import * as React from "react";

const Footer = () => {
  return (
    <footer className="footer grid">
      <p className="txt-x-small">
        <em>
          Copyright © 2001-
          {new Date().getFullYear()} Astro-Kompass, Alle Rechte vorbehalten.
        </em>
      </p>
    </footer>
  );
};

export default Footer;

import { Link } from "gatsby";
import * as React from "react";
import SkipNavLink from "./skipNavLink";
import { useSiteMetadata } from "./utils/useSiteMetadata";

const Header = () => {
  const { headerLinks } = useSiteMetadata();
  const menuRef = React.useRef<HTMLInputElement>(null);
  const menuClick = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.keyCode === 13 && menuRef.current) menuRef.current.checked = !menuRef.current.checked;
  };
  return (
    <header className="header">
      <SkipNavLink />
      <nav className="header__nav">
        <Link to="/" className="header__logo" aria-label="Astro-Kompass homepage" title="Astro-Kompass homepage">
          <img src="/logo.png" alt="Logo" />
        </Link>
        <input type="checkbox" id="hamburger" ref={menuRef} className="header__checkbox hide-screens" />
        <label className="header__toggle" htmlFor="hamburger" tabIndex={0} onKeyDown={menuClick}>
          <span className="header__toggle-icon" aria-hidden="true"></span> Menü
          <span className="header__toggle-opened hide-screens">expanded</span>
          <span className="header__toggle-closed hide-screens">collapsed</span>
        </label>
        <div className="header__links">
          {headerLinks.map((item, index) => (
            <Link
              className={`header__link ${index === headerLinks.length - 1 ? `button` : ``}`}
              key={index}
              to={item.link}
              title={item.name}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;

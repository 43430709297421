import { useStaticQuery, graphql } from "gatsby";

type SiteMetaDataProps = {
  site: {
    siteMetadata: {
      headerLinks: {
        name: string;
        link: string;
      }[];
      siteUrl: string;
      title: string;
      description: string;
    };
  };
};

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<SiteMetaDataProps>(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteUrl
            title
            description
            headerLinks {
              link
              name
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

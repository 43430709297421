module.exports = {
  pathPrefix: `/`, // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: `Astro Kompass`, // Navigation and Site Title
  titleAlt: `Astro Kompass`, // Title for JSONLD
  description: `Erarbeitung praktischer Lösungsansätze in Kindererziehung, Partnerschaft, Beruf, Gesundheit, Entscheidungshilfe, Transit-Beratung und Personalberatung.`,
  headline: `Erarbeitung praktischer Lösungsansätze in Kindererziehung, Partnerschaft, Beruf & Gesundheit.`, // Headline for schema.org JSONLD
  url: `https://astrokompass.ch`, // Domain of your site. No trailing slash!
  logo: `/AstrologieBeratung.jpg`, // Used for SEO
  ogLanguage: `de_CH`, // Facebook Language
  siteLanguage: `de`,
  headerLinks: [
    {
      name: `Astrologieberatung`,
      link: `/`,
    },
    {
      name: `Astrologie`,
      link: `/astrologie`,
    },
    {
      name: `Persönlich`,
      link: `/persoenlich`,
    },
    {
      name: `Medienpräsenz`,
      link: `/medienpraesenz`,
    },
    {
      name: `Vorträge`,
      link: `/vortraege`,
    },
    {
      name: `Kontakt`,
      link: `/kontakt`,
    },
  ],
  // JSONLD / Manifest
  favicon: `src/favicon.png`, // Used for manifest favicon generation
  shortName: `Astrokompass`, // shortname for manifest. MUST be shorter than 12 characters
  author: `RomeoStoll`, // Author for schemaORGJSONLD
  themeColor: `#e2e2f2`,
  backgroundColor: `#ffffff`,
  twitter: ``, // Twitter Username
  facebook: ``, // Facebook Site Name
  skipNavId: `reach-skip-nav`, // ID for the "Skip to content" a11y feature
};

import * as React from "react";
import website from "../../config/website";
import Footer from "./footer";
import Header from "./header";

const Layout: React.FC = ({ children }) => (
  <>
    <noscript>Sie müssen JavaScript aktivieren, um diese Webseite zu besuchen.</noscript>
    <Header />
    <main id={website.skipNavId} className="grid">
      {children}
    </main>
    <Footer />
    <div className="unsupported">
      <p>
        <strong>Tut uns leid, diese Website verwendet Funktionen, die Ihr Browser nicht unterstützt.</strong>
        {` `}
        Aktualisieren Sie auf eine neuere Version von{` `}
        <a
          href="https://www.mozilla.org/de-CH/firefox/new/"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="link_external link_underline"
        >
          Firefox
        </a>
        ,{` `}
        <a
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="link_external link_underline"
        >
          Chrome
        </a>
        ,{` `}
        <a
          href="https://support.apple.com/downloads/safari"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="link_external link_underline"
        >
          Safari
        </a>
        , oder{` `}
        <a
          href="https://www.microsoft.com/de-ch/edge"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="link_external link_underline"
        >
          Edge
        </a>
        {` `}
        und Sie sind startklar.
      </p>
    </div>
  </>
);

export default Layout;

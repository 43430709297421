import * as React from "react";
import Helmet from "react-helmet";

type Props = {
  url: string;
  type: string;
  name: string;
  title: string;
  desc: string;
  image: string;
  locale: string;
};
const Facebook: React.FC<Props> = ({ url, name, type, title, desc, image, locale }) => (
  <Helmet>
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={title} />
    {image && (
      <meta
        property="og:image:type"
        content={`image/${image.slice(-4).toLowerCase() === `jpeg` ? image.slice(-4).toLowerCase() : image.slice(-3)}`}
      />
    )}
  </Helmet>
);

export default Facebook;

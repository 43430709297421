import React from "react";
import { graphql, Page } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SEO from "../components/SEO";
import { WindowLocation } from "@reach/router";

interface PageTemplateProps extends Page {
  location: WindowLocation;
  data: {
    mdx: {
      frontmatter: {
        title: string;
        date: string;
        featuredImage?: {
          childImageSharp: GatsbyImageProps;
        };
      };
      body: any;
    };
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data, location }) => {
  const { mdx } = data; // data.mdx holds your post data
  const { frontmatter, body } = mdx;
  return (
    <>
      <SEO title={frontmatter.title} keywords={frontmatter.title} pathname={location.pathname} />
      <Layout>
        <MDXRenderer>{body}</MDXRenderer>
      </Layout>
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

import React from "react";
import website from "../../config/website";

const SkipNavLink = ({ children, ...props }: { children: React.ReactNode }) => (
  <a {...props} className="hide-screens button header__skip" href={`#${website.skipNavId}`} data-reach-skip-nav-link>
    {children}
  </a>
);

export default SkipNavLink;

SkipNavLink.defaultProps = {
  children: `Zum Inhalt springen`,
};

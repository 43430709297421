import { RouteComponentProps } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import Facebook from "./facebook";
import Twitter from "./twitter";

const SEO: React.FC<SEOProps> = ({ title, desc, banner, pathname, keywords }) => {
  const { site } = useStaticQuery<SEOQueryProps>(query);

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site;

  const seo = {
    title: `${title || defaultTitle} | ${defaultTitle}`,
    description: desc || defaultDescription,
    image: `${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ``}`,
    keywords: keywords,
    canonical: `${siteUrl}${pathname || ``}`,
  };
  const image = siteUrl.concat(banner || defaultBanner);

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": `ListItem`,
      item: {
        "@id": siteUrl,
        name: `Homepage`,
      },
      position: `1`,
    },
  ];

  const schemaOrgWebPage = {
    "@context": `http://schema.org`,
    "@type": `WebPage`,
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: defaultTitle,
    author: {
      "@type": `Person`,
      name: author,
    },
    copyrightHolder: {
      "@type": `Person`,
      name: author,
    },
    copyrightYear: `2020`,
    creator: {
      "@type": `Person`,
      name: author,
    },
    publisher: {
      "@type": `Person`,
      name: author,
    },
    datePublished: `2020-04-18T10:30:00+01:00`,
    dateModified: buildTime,
    image: {
      "@type": `ImageObject`,
      url: image,
    },
  };

  const breadcrumb = {
    "@context": `http://schema.org`,
    "@type": `BreadcrumbList`,
    description: `Breadcrumbs list`,
    name: `Breadcrumbs`,
    itemListElement,
  };

  // React.useEffect(() => {
  //   const pageTitle = seo.title;
  //   let attentionMessage: string[] = [`🍷`];
  //   let blinkEvent: NodeJS.Timeout;

  //   if (typeof window === `undefined` || !window.document) {
  //     return;
  //   }

  //   function blink() {
  //     blinkEvent = setInterval(function () {
  //       if (attentionMessage.length === 6) {
  //         attentionMessage = [`🍷`];
  //       } else {
  //         attentionMessage.push(`🍷`);
  //       }
  //       document.title = attentionMessage.join(` `);
  //     }, 500);
  //   }

  //   const eventListener = () => {
  //     const isPageActive = !document.hidden;
  //     if (!isPageActive) {
  //       blink();
  //     } else {
  //       document.title = pageTitle;
  //       clearInterval(blinkEvent);
  //     }
  //   };

  //   document.addEventListener(`visibilitychange`, eventListener);

  //   return () => {
  //     document.removeEventListener(`visibilitychange`, eventListener);
  //   };
  // }, []);

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} dir="ltr" />
        <meta name="language" content={siteLanguage} />
        <meta property="og:site_name" content={defaultTitle} />
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={seo.canonical} />
        <meta name="image" content={image} />
        {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={image}
        title={seo.title}
        type={`website`}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={image} type="summary" desc={seo.description} username={twitter} />
    </>
  );
};

interface SEOProps {
  title: string;
  desc?: string;
  banner?: string;
  pathname: RouteComponentProps;
  keywords: string;
}

type SEOQueryProps = {
  site: {
    buildTime: string;
    siteMetadata: {
      siteUrl: string;
      defaultTitle: string;
      defaultDescription: string;
      defaultBanner: string;
      headline: string;
      siteLanguage: string;
      ogLanguage: string;
      author: string;
      twitter: string;
      facebook: string;
    };
  };
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;
